@import '../root/globals.scss';

.modal-window {
  position: absolute;
  left: 50%;
  transform: translate(-50.1%, -50.1%);
  min-width: 16em;
  min-height: 8em;
  padding: 2.5em;
  background-color: $white;
  max-height: 80vh;

  font-family: $font;
  outline: none;

  &.vehicle-modal, &.driver-modal {
    padding: 0;
    padding-top: 1.5rem;
  }

  h2 { margin-top: 0; }

  div.modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 320px;
    max-height: 80vh;
    overflow-y: auto;

    h3 { margin-top: 0; }

    button {
      font-size: 1em;
      color: $white;
      padding: 0.75em 1.5em 0.75em 1.5em;
      border: 0px;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      margin: 0;
    }

    button.cancel-button {
      background-color: $primary-color;

      &:hover {
        background: darken($primary-color, 10%);
      }
    }

    button.delete-button {
      background-color: $error;

      &:hover {
        background: darken($error, 10%);
      }
    }

    div.options {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 1.5em;
      padding-top: 1.5em;
      border-top: 1px #F8F9FD solid;

      button { margin-left: 1em; }
    }

  }

  div.close-modal {
    position: absolute;
    right: 0em;
    top: -2em;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    font-size: 0.8em;
    -webkit-backface-visibility: hidden;

    &::before {
      content: 'Close';
      display: inline-block;
      color: #fff;
      font-weight: 700;
      margin-right: 6px;
    }

    &:hover { opacity: 0.8; }

    svg path { fill: #fff; }
  }
}

.modal-overlay {
  position: fixed;
  z-index: 9001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
}


.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close {

}

.ReactModal__Content {
  opacity: 0;
  top: -100%;
  transition: all 0.3s 0.15s ease-in-out;
}
.ReactModal__Content--after-open {
  opacity: 1;
  top: 50%;
}

.driver-modal {
  padding-right: 0;
  padding-left: 0;
  padding-top: 3rem;
  padding-bottom: 0;
}
