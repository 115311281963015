$success: #00C0B4;
$transition-time: 0.3s;
$black: #212121;
$grey: #474747;
$inputBorder: #D6D6D6;
$menuHighlight: #E5F0FE;
$primaryColor: #F66C57;

@mixin selectMixin {
  div.select {
    font-size: 0.9rem;
    font-weight: 300;

    svg {
      height: 100%;
    }

    input {
      position: absolute;
      top: 0px;
      left: 1em;
      text-indent: 0;
    }

  }

  div.select__control {
    &--is-focused {
      border-color: $primaryColor;
      transition: border-color $transition-time ease-in-out;
      box-shadow: none;

      &:hover {
        border-color: $primaryColor;
      }
    }

  }

  div.select__option {
    &:hover {
      background-color: lighten($primaryColor, 30%);
      color: $black;
    }

    &--is-focused {
      background-color: lighten($primaryColor, 30%);
    }

    &--is-selected {
      background-color: $primaryColor;
    }

  }
}

.index-table {
  .pagingRow, .controlRow {
    max-width: 100%;
  }

  .header, .contents {
    span {
      min-width: 7em;
    }
  }
}

.link-vehicle-add-cell {
  background: #F7F8FA;
  cursor: pointer;
  height: 100%;

  > span { padding-left: 1rem }
}

div.table {
  font-size: 15px;

  div.controlRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    > div {
      margin-bottom: 0.5rem;
    }

    h2 {
     margin-bottom: 0;
     white-space: nowrap;
     padding-right: 1rem;
    }
    .new-link {
      button {
        background: $primaryColor;
        border: 1px solid $primaryColor;
        border-radius: 5px;;
        color: white;
        width: 8rem;
        padding: 0.7rem 0.5rem;
        letter-spacing: 0.5px;
        cursor: pointer;

        svg {
          margin-right: 0.5rem;
        }
      }
    }
    .centre {
      display: flex;
      align-items: center;
    }

    button.columns {
      padding: 0;
      font-size: 1.4em;
      padding: 0;
      margin: 0;
      border-radius: 0;
      color: $black;
      box-shadow: none;
      background: none;
      border: none;
      cursor: pointer;
      svg path {
        fill: $black;
      }
    }

    div.leftSide {
      display: flex;
      div.search, .date-select {
        position: relative;

        svg {
          margin: auto;
          color: $grey;
          position: absolute;
          top: 0.75rem;
          left: 0.75rem;
        }

        input {
          padding: 0.66rem;
          border: 1px solid $inputBorder;
          border-radius: 0.4em;
          text-indent: 30px;
          width: 11rem;
          font-family: 'Ubuntu';

          &:focus {
            border-color: $primaryColor;
            transition: border-color 0.3s ease-in-out;
            box-shadow: none;
            outline: none;
          }
        }
      }
      div.search input {
        margin-right: 1rem;
      }
    }

    div.leftSide {

      > div {
        display: flex;

        > label, > span {
          margin: auto 1em;
        }

        > div.sortSelect {
          @include selectMixin;
          width: 8rem;
          font-weight: 400;
          text-transform: capitalize;

          > div {
            cursor: pointer;

            > div {
              color: $black;

              > span {
                width: 0;
              }

              > div {
                color: $black;
              }
            }
          }
        }

        .sortDirection {
          font-size: 1.4em;
          cursor: pointer;
          padding: 0 0 0 1em;
          color: $black;
          border-left: 1px solid lighten($black, 65%);
        }
      }

    }

    div.rightSide {
      display: flex;

      > div {
        display: flex;
        white-space: inherit;

        &:first-of-type { margin-right: 1em; }

        > label {
          margin: auto 1em;
        }

        > div {
          font-weight: bold;
        }

        > div.pageSelect {
          @include selectMixin;
          min-width: 80px;
          font-weight: 400;

          div {
            cursor: pointer;
          }
        }

        > div.categorySelect {
          @include selectMixin;
          min-width: 150px;

          div {
            cursor: pointer;
          }
        }
      }
    }
  }

  hr {
    opacity: 0.2;
    margin: 1rem 0 .5rem 0;
  }

  div.header {
    display: flex;
    width: auto;
    margin: 1em 0 0 0;
    padding: 0 0 0 1em;
    padding-right: 2rem;
    span {
      flex: 1 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
      padding-right: 2em;
    }

    span.flex-0 {
      flex: 0 0 min-content;
      min-width: unset;
    }

    span.flex-1 {
      flex: 1 0 0;
    }

    span.flex-2 {
      flex: 2 0 0;
    }

    span.flex-3 {
      flex: 3 0 0;
    }

    span.flex-4 {
      flex: 4 0 0;
    }

    span.flex-5 {
      flex: 5 0 0;
    }
  }

  .data-body {
    width: auto;
    overflow: auto;
    overflow-y: hidden;
  }
  div.contents {
    min-height: 16rem;
    max-height: 30rem;

    div.row {
      display: flex;
      width: auto;
      height: 47px;
      align-items: center;
      background: white;
      color: $black;
      margin: 0;
      transition: background-color ease-in-out 0.3s;

      &:first-child > * {
        border-top: 1px solid rgba(186, 194, 215, .3);
      }
      > * {
        border-bottom: 1px solid rgba(186, 194, 215, .3);
        &:first-child {
          padding-left: 1rem;
        }
      }
      .driver {
        background: rgba(234, 237, 243, .4);
        padding: 0 1rem;
        min-width: 9.5em;
      }

      .navbar-item-link {
        cursor: pointer;
      }

      &:hover { background: #f56c5810; }

      svg {
        margin-left: 4em;
      }

      span {
        flex: 1 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 2em;
        display: flex;
        align-items: center;
        height: 100%;

        a {
          color: $primaryColor;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      span.flex-0 {
        flex: 0 0 min-content;
        min-width: unset;
      }

      span.flex-1 {
        flex: 1 0 0;
      }

      span.flex-2 {
        flex: 2 0 0;
      }

      span.flex-3 {
        flex: 3 0 0;
      }

      span.flex-4 {
        flex: 4 0 0;
      }

      span.flex-5 {
        flex: 5 0 0;
      }
    }
  }

  div.pagingRow {
    margin: 2em 0 .5rem 0;
    display: flex;

    span.info {
      font-size: 10pt;
      margin: auto 0;
      color: $grey;
    }

    span.control {
      margin-left: auto;
      white-space: nowrap;

      svg {
        margin: 0 0.6em;
        cursor: pointer;
      }

      span {
        border-radius: 0.3em;
        padding: 0.3em 0.6em;
        margin: 0 0.2em;
      }

      span.page {
        background: $primaryColor;
        color: white;
      }

      span.page-button {
        background: white;
        cursor: pointer;
      }
    }
  }
}
