$link: #FD3738;
$error: #F6DEE1;

.form-status {
  background: mediumseagreen;
  padding: 1em;
  text-align: center;
  border-radius: 5px;
  animation: statusBar .3s forwards;
  margin-bottom: 1rem;
  font-size: .9rem;
  box-sizing: border-box;
  width: 100%;

  &.error {
    background: $error;
    color: $link;
  }
}

@keyframes statusBar {
  0% { opacity: 0; }
  100% { opacity: 1; }
}