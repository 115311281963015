@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu'), url(./fonts/Ubuntu-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: local('Ubuntu'), url(./fonts/Ubuntu-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: local('Ubuntu'), url(./fonts/Ubuntu-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: local('Ubuntu'), url(./fonts/Ubuntu-BoldItalic.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body button { font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
