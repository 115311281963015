@import '../root/globals.scss';

.layout-under-topbar {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 5;

  h1 {
    margin-bottom: 1.2em;
    color: $primary-color;
    font-family: $font;
  }

  &.invert {
    filter: invert(1)
  }
}

.layout-barless-page {
  flex: 1;
}

.layout-content-pane {
  box-sizing: border-box;
  flex: 1;
  height: calc(100vh - 80px);
  background-color: #F5F6F9;
  padding: 1rem 2.5em;
  overflow-x: hidden;
  overflow-y: scroll;

  .index-controls {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;

    button:last-of-type { margin-right: 0; }
  }

  .create-account-container {
    height: calc(100vh - 170px);
    min-height: 0;
  }
}

h3 {
  font-size: 1.35rem;
}

h4 {
  font-size: 1.2rem;
  color: $black;
}

.hidden {
  display: none;
}
