@import '../root/globals.scss';

td .dropdown-button .dropdown-content {
  top: 107%;
  left: 0;
  right: auto;
}

.dropdown-button {
  position: relative;
  display: inline-block;

  button {
    border: none;
    background: white;
  }

  .menu-icon svg {
    color: #B9B9B9;
    &:hover { color: black; }
  }

  i {
    padding-left: 0.5em;
  }

  &.dropup .dropdown-content {
    top: auto;
    bottom: 95%;
    left: 0;
    right: auto;
  }

  .dropdown-content {
    font-family: $font;
    position: absolute;
    right: 0;
    background-color: $white;
    min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: 1px solid $ghost-white;
    border-radius: 4px;
    top: 95%;
    z-index: 15;

    list-style-type: none;
    white-space: nowrap;

    visibility: hidden;
    transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;

    &.visible {
      visibility: visible;
    }

    li.danger {
      color: $danger;
      background: $white;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: $danger;
        color: $white;

        svg {
          background: transparent;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    li.disabled {
      cursor: default;

      &:hover {
        background-color: $white;
      }
    }

    li {
      padding: 0.75em;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 0.96em;

      &:hover {
        background-color: $menu-highlight;
      }

      svg {
        min-width: 1.3em;
        cursor: inherit;
      }
      img {
        width: 0.8rem;
        padding-right: 1rem;
        top: 0.1rem;
        position: relative;
        filter: brightness(15%) grayscale(100%);
      }

      label {
        cursor: inherit;
      }
    }
  }
}
