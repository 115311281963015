.react-tabs {
  margin-top: 2rem;

  ul {
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
    border: none;
  }
  li {
    background: #ebebeb;
    height: 2.5rem;
    border-radius: 0;
    width: 10rem;
    text-align: center;
    line-height: 2.5rem;
    border: none;
    bottom: 0;
    border-right: 0.1rem solid #c4c4c4;
    &:last-child {
      border-right: none;
    }

    &.react-tabs__tab--selected {
      background: black;
      color: white;
      border-top: 0;
    }
  }

  .react-tabs__tab-panel {
    background: white;
    padding: 1rem;
  }
}
