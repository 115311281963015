
$link: #F66C57;

@keyframes slideDown {
  0% {
    transform: translateY(-150%) translateZ(0);
    opacity: 0;
  }
  100% { 
    transform: translateY(0) translateZ(0); 
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(150%) translateZ(0);
    opacity: 0;
  }
  100% { 
    transform: translateX(0) translateZ(0); 
    opacity: 1;
  }
}

.login-wrapper {

  background: #F5F6F9 0% 0% no-repeat padding-box;
  min-height: 100vh;

  .header {
    height: 90px;
    background: white;
    width: 100%;
    position: relative;
    z-index: 5;

    img {
      padding-left: 30px;
      padding-top: 30px;
      height: 30px;
    }

    @media screen and (max-width: 499px) {
      height: auto;
      img {
        height: 25px;
        padding: 20px 0 15px 20px;
      }
    }
  }

  .car {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    position: relative;
    z-index: 1;
    transform: translateY(-150%);
    opacity: 0;
    animation: slideDown .5s .15s ease-in-out forwards;

    img {
      max-width: 80%;
      max-height: 25vh; // fits better with shorter laptop screens
    }
  }

  .main {
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 499px) {
      width: 90%;
      padding: 0 5%;
    }
  }

  h1 {
    color: black; 
    margin-bottom: 1.1rem;
    font-weight: 400;
    font-size: 2.2rem;
    text-align: center;
  }

  .sub-header {
    text-align: center;
    font-size: 0.9rem;
    margin: auto;
    padding-bottom: 3em;
    line-height: 150%;
  }

  .action-button {
    border-radius: 5px;
    color: white;
    font-size: 18px;
    padding: .75rem 0;
    cursor: pointer;
    font-family: 'Ubuntu';
    background: $link;
    border: none;
    outline: none;
    max-height: 47px;
    transition: opacity .15s ease-in-out;
    margin-top: 1rem;

    &:disabled {
      background: #B9B9B9;
      border-color: #B9B9B9;
      cursor: not-allowed;
      opacity: 1;
    }

    &:hover {
      opacity: .7;
    }
  }

  .change {
    text-align: center;
    font-size: .9rem;
    color: $link;
    cursor: pointer;
    margin-top: 30px;

    &:hover {
      text-decoration: underline;
    }

    svg {
      padding-right: 0.5em;
      vertical-align: top;
      margin-top: 1px;
      &.right {
        padding-right: 0;
        padding-left: 0.5em;
      }
    }
  }

  .change-success {
    .success {
      font-size: 1rem;
      line-height: 150%;
      text-align: center;
      margin-bottom: 2rem;
    }
    .check-circle {
      width: 6rem;
      height: 6rem;
      color: #53B761;
      display: block;
      margin: 0 auto;
    }
  }

  .emailed {
    text-align: center;
    font-size: 1rem;

    .success { line-height: 150%; }

    h4 { margin-top: 0; }
  }

  .envelope {
    margin: auto;
    color: $link;
    width: 6rem;
    height: 4.5rem;
    margin-bottom: 1rem;
  }

  .email {
    color: $link;
  }
  .resend {
    color: $link;
    cursor: pointer;
    &:hover { text-decoration: underline; }
  }

  .welcome-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
    margin-bottom: 3em;

    .button {
      display: flex;
      flex-direction: column;
      height: 120px;
      width: 180px;
      border: 2px solid $link;
      border-radius: 5px;
      transition: all .15s ease-in-out;
      text-decoration: none;

      &:hover {
        background: $link;
        span, svg { color: white; }
      }

      svg, span {
        margin: auto;
        transition: all .15s ease-in-out;
      }
      svg {
        color: $link;
        margin-bottom: 0.5em;
      }
      span {
        margin-top: 0.5em;
        color: #000;
      }
    }
  }

  @media screen and (max-height: 768px) {
    padding-bottom: 2rem; 
  }

}

