.modal-window.column-select {
  width: 46em;
  height: 65vh;

  .select {
    width: 100%;
    min-width: 70vw;

    .select__control {
      height: 100%;

      .select__value-container {
        padding-left: 0.3rem;
        align-self: center;
      }
      .select__indicators { align-self: center; }
      .select__multi-value {
        border-radius: 5px;
        padding: 0 0 0 0.5rem;

        .select__multi-value__remove {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          margin-left: 0.5rem;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          transition: all 0.3s ease-in-out;

          &:hover {
            cursor: pointer;
            background: #DE350B;
            color: white;
          }
        }
      }
    }
  }

  div.modal-content {
    h3 {
      color: #633C76;
      font-weight: 400;
      font-size: 1.8rem;
    }
   div.options {
      display: flex;
      justify-content: flex-start;

      button {
        width: 8rem;
        margin-left: 0;
        margin-right: 1rem;
        letter-spacing: 125%;
        padding: 0.7rem 0.5rem;

        &.ok {
          background: #F66C57;
          color: white;
        }
        &.default {
          background: white;
          color: #F66C57;
          border: 2px solid #F66C57;
          font-family: 'Ubuntu';
        }
      }
    }
  }
}
