$grey: #474747;
$gray: $grey;
$light-grey: #C6C8CA;
$error: #FF3A64;
$danger: crimson;
$mildperil: thistle;
$success: #00C0B4;
$black: #212121;
$black-10:lighten(#212121, 80%);
$text-black: #212121;
$white: #ffffff;
$ghost-white: #F8F9FD;
$primary-color: #4D74ED;
$menu-highlight:#e5f0fe;
$faded-white: #f5f5f5;
$light-border:#EFF4F5;
$input-border: #D6D6D6;

$font: "Ubuntu", "sans-serif"
