.credit-modal {
    padding: 0 2.5em 2.5em 2.5em;

    .close-modal {
        display: none;
    }

    .modal-content {
        width: fit-content;
        max-width: 300px;
        height: 500px;
        align-items: center !important;
        justify-content: space-between;
        text-align: center;
        overflow: hidden;

        img {
            width: 20vw;
            min-width: 300px;
        }

        .modal-title {
            font-size: 28px;
            margin: 0 0 12px 0;
        }

        .modal-subtext {
            max-width: 300px;
            font-size: 12px;
        }
    }
}