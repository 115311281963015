.date-select {
  .active {
    position: absolute;
    background: white;
    width: 43rem;
    left: -12rem;
    border: 0.1rem solid darkgray;
    border-radius: 5px;;
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 1rem;
    margin-top: 1rem;
    z-index: 1;
    box-shadow: 0.25rem 0.25rem 0.25rem #ccc;
  }

  .main {
    display: flex;
    flex-direction: row;
  }
  .controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
      background: #efefef;
      border: 1px solid #efefef;
      border-radius: 5px;;
      color: black;
      width: 8rem;
      padding: 0.7rem 0.5rem;
      letter-spacing: 0.5px;
      cursor: pointer;
      margin: 0.1rem;
    }
    button.always {
      background: #F66C57;
      border: 1px solid #F66C57;
      color: white;
    }
  }

  .tip {
    transform: rotate(-45deg);
    background: #FFFFFF;
    width: .75rem;
    height: .75rem;
    position: relative;
    bottom: 0.5rem;
    left: 11.7rem;
    border-top: 0.1rem solid darkgray;
    border-right: 0.1rem solid darkgray;
  }

  // Overwriting some lib styles
  .date-range-picker {
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: #FEE7E4;
      color: black;

    }
    .DayPicker-Day {
      border-radius: 0 !important;
      &:hover {
        background-color: #F66C57 !important;
        color: white !important;
      }
    }
    .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
  }
}

.DayPicker-Weekday abbr[title] {
  font-size: .8rem;
  text-transform: uppercase;
}
.DayPicker-Caption > div {
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: #000;
}
.DayPicker-Caption > div { margin-bottom: .75rem; }
.DayPicker-NavButton { 
  margin-top: -1px;
}
.DayPicker-NavButton--prev { left: 1.5em; }
